import dynamic from 'next/dynamic';
import Spin from '@/components/ui/Spin';
import { useUser } from '@auth0/nextjs-auth0';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useQuery } from '@tanstack/react-query';
import { Badge } from 'antd';
import { useState } from 'react';

const IssuesModal = dynamic(() => import('@/components/ApprovalIssues/IssuesModal').then((mod) => mod.IssuesModal), {
	suspense: true
});

const PUSHER_CHANNEL = process.env.NEXT_PUBLIC_PUSHER_CHANNEL;

export function ViewIssuesButton() {
	const { user } = useUser();
	// TODO - Make sure user has approve permissions. (if not, return null;)
	const [showDetails, setShowDetails] = useState(false);

	const fetchStuckItems = async () => {
		const res = await fetch('/api/issues');
		const data = await res.json();

		console.log('Stuck Items Data :: ', data);

		return data;
	};

	const { data, isLoading } = useQuery({
		queryKey: ['stuck-items'],
		queryFn: async () => await fetchStuckItems(),
		enabled: !!user
	});

	const handleShowDetails = () => {
		setShowDetails(!showDetails);
	};

	if (isLoading) {
		// * Stuck Items Loading...
		return <Spin />;
	}

	if (!data) {
		console.warn('No data returned from API call for stuck items...');
		return null;
	}

	if (!data.count) {
		// * No Stuck Items
		return null;
	}

	return (
		<>
			<IssuesModal isVisible={showDetails} toggleVisible={handleShowDetails} issues={data.invoices} />
			<Badge count={data.count} size="small">
				<ExclamationIcon
					className="w-10 h-10 text-red-400 animate-pulse hover:cursor-pointer"
					onClick={handleShowDetails}
				/>
			</Badge>
		</>
	);
}
